import { StyledIcon } from '../Icon.Styled'
import { IconProps } from '../Icon.Types'

export const ArrowSmLeftIcon = (props: IconProps) => (
  <StyledIcon
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <path
      d="M14 17.4139L9.29301 12.7069C8.90201 12.3159 8.90201 11.6839 9.29301 11.2929L14 6.58594L15.414 7.99994L11.414 11.9999L15.414 15.9999L14 17.4139Z"
      fill="currentColor"
    />
  </StyledIcon>
)
