import { createSlice } from '@reduxjs/toolkit'

import { AppState } from '../store'

interface UIState {
  loading: boolean
  error: null | string
  isSidebarExpanded: boolean
  isSidebarExpandedManually?: boolean
  isFilterExpanded: boolean
  keyEventComponentsUuids: string[]
}

const initialState: UIState = {
  loading: false,
  error: null,
  isSidebarExpanded: true,
  isSidebarExpandedManually: undefined,
  isFilterExpanded: false,
  keyEventComponentsUuids: [],
}

const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    reset: () => initialState,
    setSidebarExpanded: (state, { payload }) => {
      state.isSidebarExpanded = payload
    },
    setSidebarExpandedManually: (state, { payload }) => {
      state.isSidebarExpandedManually = payload
    },
    setFilterExpanded: (state, { payload }) => {
      state.isFilterExpanded = payload
    },
    registerKeyEventComponent: (state, { payload }: { payload: string }) => {
      state.keyEventComponentsUuids.push(payload)
    },
    unregisterKeyEventComponent: (state, { payload }: { payload: string }) => {
      state.keyEventComponentsUuids = state.keyEventComponentsUuids.filter(
        (uuid) => uuid !== payload
      )
    },
  },
})

export const {
  setSidebarExpanded,
  setSidebarExpandedManually,
  setFilterExpanded,
  registerKeyEventComponent,
  unregisterKeyEventComponent,
} = uiSlice.actions

export const uiSelector = (state: AppState) => state.ui
export default uiSlice.reducer
