import { StyledIcon } from '../Icon.Styled'
import { IconProps } from '../Icon.Types'

export const BCommentIcon = (props: IconProps) => (
  <StyledIcon
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      d="M21.965 9.57422C21.604 14.8202 16.384 18.9992 10 18.9992C9.535 18.9992 9.069 18.9732 8.606 18.9272C10.619 20.5132 13.545 21.3032 16.552 20.8942L22 23.6172V18.4022C23.293 17.1592 24 15.6112 24 13.9992C24 12.3722 23.277 10.8212 21.965 9.57422Z"
      fill="currentColor"
    />
    <path
      d="M10 1C4.477 1 0 4.582 0 9C0 10.797 0.75 12.45 2 13.785V19L6.833 16.584C7.829 16.85 8.892 17 10 17C15.523 17 20 13.418 20 9C20 4.582 15.523 1 10 1Z"
      fill="currentColor"
    />
  </StyledIcon>
)
