import { StyledIcon } from '../Icon.Styled'
import { IconProps } from '../Icon.Types'

export const IDeleteIcon = (props: IconProps) => (
  <StyledIcon
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 12V13H18V11H6V12Z"
      fill="currentColor"
    />
  </StyledIcon>
)
