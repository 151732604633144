import { configureStore, isPlain } from '@reduxjs/toolkit'
import { Timestamp } from 'firebase/firestore'
import { loadExercisesAsObject } from 'fitify-data/src'

import activityBuilderGeneralSlice, {
  setExercisesDataCollection,
} from '@/store/activity/builder/builderGeneralSlice'
import activityBuilderSlice from '@/store/activity/builder/builderSlice'
import activityCalendarSlice from '@/store/activity/calendar/calendarSlice'
import { activityCalendarLocalStorageMiddleware } from '@/store/activity/calendar/listener'
import activitySamplesSlice from '@/store/activity/samples/samplesSlice'
import loggedUserSlice from '@/store/loggedUser/loggedUserSlice'
import sessionSlice from '@/store/session/sessionSlice'
import uiReducer from '@/store/ui/uiSlice'
import userSlice from '@/store/user/userSlice'

import aiHelperSlice from './ai/aiHelperSlice'

export const reducer = {
  aiHelper: aiHelperSlice,
  activityBuilder: activityBuilderSlice,
  activityBuilderGeneral: activityBuilderGeneralSlice,
  activityCalendar: activityCalendarSlice,
  activitySamples: activitySamplesSlice,
  user: userSlice,
  loggedUser: loggedUserSlice,
  session: sessionSlice,
  ui: uiReducer,
}

function createStore() {
  return configureStore({
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        immutableCheck: { warnAfter: 128 },
        serializableCheck: {
          warnAfter: 128,
          isSerializable: (value: any) =>
            value instanceof Timestamp || isPlain(value),
        },
      }).prepend(activityCalendarLocalStorageMiddleware),
    reducer,
  })
}

const store = createStore()

export type AppState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

const initializeStoreAsyncData = async () => {
  const exercisesObject = await loadExercisesAsObject()
  store.dispatch(setExercisesDataCollection(exercisesObject))
}

void initializeStoreAsyncData()

export default store
