import React, { useEffect } from 'react'
import {
  cssTransition,
  toast,
  ToastContainer as ToastifyContainer,
  type ToastItem,
} from 'react-toastify'
import styled from 'styled-components'

import { Fitify } from '@/types/user'

const transition = cssTransition({
  appendPosition: true,
  collapse: false,
  collapseDuration: 0,
  enter: 'Toastify--animate Toastify__bounce-enter',
  exit: 'Toastify--animate Toastify__custom-exit',
})

const StyledToastContainer = styled(ToastifyContainer)`
  /* stylelint-disable selector-class-pattern */
  &&&.Toastify__toast-container {
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: center;
    justify-content: center;
    width: auto;
    max-width: 540px;
  }
  .Toastify__toast {
    min-height: auto;
    margin: 0;
    padding: 0;
    background: transparent;
    box-shadow: none;
  }
  .Toastify__toast-body {
    margin: 0;
    padding: 0;
  }

  @keyframes ToastifyCustomExitAnimation {
    from {
    }
    to {
      visibility: hidden;
    }
  }

  .Toastify__custom-exit--top-center {
    animation-name: ToastifyCustomExitAnimation;
    animation-duration: 0s;
    animation-timing-function: ease-in;
  }
`

export const ToastContainer: React.FC = () => {
  // Prevent duplicate messages
  useEffect(() => {
    let messages: { id: string | number; message: string }[] = []

    const unsubscribe = toast.onChange(
      (payload: ToastItem<Partial<Pick<Fitify.ToastMessage, 'message'>>>) => {
        switch (payload.status) {
          case 'added':
            messages.forEach((message) => {
              if (message.message === payload.data.message) {
                toast.dismiss(message.id)
              }
            })
            messages.push({ id: payload.id, message: payload.data.message! })
            break
          case 'removed':
            messages = messages.filter((message) => message.id !== payload.id)
            break
          default:
            break
        }
      }
    )

    return () => {
      unsubscribe()
    }
  }, [])

  return (
    <StyledToastContainer
      position="top-center"
      closeButton={false}
      hideProgressBar
      transition={transition}
    />
  )
}
