import {
  createAsyncThunk,
  createSlice,
  isAnyOf,
  type PayloadAction,
  type SerializedError,
} from '@reduxjs/toolkit'
import {
  ActivityApi,
  FitnessDataApi,
} from 'fitify-types/src/types/coach-admin-api'
import { WithId } from 'fitify-types/src/types/common'
import { ExercisesData } from 'fitify-types/src/types/exercise-collection'
import { ExerciseHistory } from 'fitify-types/src/types/exercise-history'
import { HumanCoaching } from 'fitify-types/src/types/human-coaching'
import { getUniqueArray } from 'fitify-utils/src/properties'

import { SessionService } from '@/api/services/SessionService'
import { UserService } from '@/api/services/UserService'
import { AppDispatch, AppState } from '@/store/store'
import { CoachPlan } from '@/types/CoachPlan'
import { getDatesWithinRange } from '@/utils/date'

import { createActivity, updateActivity } from '../builder/builderSlice'

import {
  getTargetDaysWithOffset,
  removePendingRequest,
  resetCopiedAndSelectedState,
} from './utils'

export interface ActivityCalendarState {
  days: WithId<HumanCoaching.Day>[]
  activitySummaries: FitnessDataApi.ActivitySummaries.Response
  selectedDays: WithId<HumanCoaching.Day>['id'][]
  copiedDays: CoachPlan.DayCopy[]
  selectedActivities: WithId<HumanCoaching.Activity>[]
  nonFitifyActivities: WithId<HumanCoaching.NonFitifyActivity>[]
  copiedActivities: CoachPlan.ActivityCopy[]
  pendingDaysRequests: Record<string, string[]>
  loading: boolean
  error: null | string
  visibleDate: {
    month: number
    year: number
    day?: number
  }
  dayOverview: {
    isOpen: boolean
    healthData: FitnessDataApi.DayOverview.Response
    coachPlanDayId: WithId<HumanCoaching.Day>['id'] | null
    sessionsAchievements: Record<string, number>
  }
  exerciseHistoryStatistics: {
    exerciseCode: string
    fromDate: string | null
    toDate: string
    statistics: ExerciseHistory.AllStatistics | null
  }[]
  exerciseHistoryDetail: {
    isOpen: boolean
    exerciseCode: string | null
    exercise: ExercisesData | null
    combinedExerciseType: HumanCoaching.CombinedExerciseType | null
    sessions: WithId<ExerciseHistory.Session>[] | null
  }
  exercisesUsageFrequency: {
    loading: boolean
    error: null | string
    exercises: Record<string, number>
    activityType: HumanCoaching.ActivityType | null
    dayId: string | null
    position: number | null
  }
  exercisesDislikes: {
    loading: boolean
    error: null | string
    exercises: ExerciseHistory.Dislikes
    activityType: HumanCoaching.ActivityType | null
  }
}

export const createDateUrlFormat = (date: { year: number; month: number }) => {
  const formattedMonth =
    date.month + 1 < 10 ? `0${date.month + 1}` : date.month + 1
  return `${date.year}-${formattedMonth}`
}

const initialState: ActivityCalendarState = {
  days: [],
  selectedDays: [],
  copiedDays: [],
  selectedActivities: [],
  nonFitifyActivities: [],
  copiedActivities: [],
  activitySummaries: {},
  pendingDaysRequests: {},
  loading: false,
  error: null,
  visibleDate: {
    month: new Date().getMonth(),
    year: new Date().getFullYear(),
  },
  dayOverview: {
    isOpen: false,
    coachPlanDayId: null,
    healthData: {
      applehealth_dailies: null,
      applehealth_sleeps: null,
      applehealth_nutritions: null,
    },
    sessionsAchievements: {},
  },
  exerciseHistoryStatistics: [],
  exerciseHistoryDetail: {
    isOpen: false,
    exerciseCode: null,
    exercise: null,
    combinedExerciseType: null,
    sessions: null,
  },
  exercisesUsageFrequency: {
    error: null,
    loading: false,
    exercises: {},
    activityType: null,
    dayId: null,
    position: null,
  },
  exercisesDislikes: {
    error: null,
    loading: false,
    exercises: {},
    activityType: null,
  },
}

export const fetchDayOverviewHealthData = createAsyncThunk<
  FitnessDataApi.DayOverview.Response,
  { userId: string; dayId: string },
  {
    dispatch: AppDispatch
    state: AppState
  }
>('user/dayOverviewHealth', async (data: { userId: string; dayId: string }) => {
  return UserService.getDayOverviewHealthData(data.userId, data.dayId)
})

type FetchDayOverviewSessionsAchievementsParams = {
  userId: string
  from?: HumanCoaching.DayRange['from']
  to?: HumanCoaching.DayRange['to']
  humanCoaching?: boolean
}

export const fetchDayOverviewSessionsAchievements = createAsyncThunk<
  Record<string, number>,
  FetchDayOverviewSessionsAchievementsParams,
  {
    dispatch: AppDispatch
    state: AppState
  }
>(
  'user/dayOverviewSessionsAchievements',
  async (data: FetchDayOverviewSessionsAchievementsParams) => {
    return SessionService.getSessionsAchievements(
      data.userId,
      data.from,
      data.to,
      data.humanCoaching
    )
  }
)

export const fetchAppleHealthActivitiesData = createAsyncThunk<
  WithId<HumanCoaching.NonFitifyActivity>[],
  { userId: string; fromDate: string; toDate: string },
  {
    dispatch: AppDispatch
    state: AppState
  }
>(
  'user/appleHealthActivities',
  async (data: { userId: string; fromDate: string; toDate: string }) => {
    return UserService.getAppleHealthActivities(
      data.userId,
      data.fromDate,
      data.toDate
    )
  }
)

const duplicateDaysAndActivities = (
  days: WithId<HumanCoaching.Day>[],
  duplicateActivities: WithId<HumanCoaching.Activity>[]
) => {
  duplicateActivities.forEach((duplicateActivity) => {
    const targetDay = days.find((day) => {
      return day.id === duplicateActivity.coach_plan_day_id
    })
    if (targetDay) {
      targetDay.activities && targetDay.activities.push(duplicateActivity)
    } else {
      days.push({
        id: duplicateActivity.coach_plan_day_id,
        activities: [duplicateActivity],
        title: null,
      })
    }
  })
}

export const fetchDays = createAsyncThunk<
  WithId<HumanCoaching.Day>[],
  {
    userId: string
    fromDate: string
    toDate: string
  },
  {
    dispatch: AppDispatch
    state: AppState
  }
>(
  'activity/calendar/fetchDays',
  async (data: { userId: string; fromDate: string; toDate: string }) => {
    return UserService.getCoachPlanDays(data.userId, data.fromDate, data.toDate)
  }
)

export const updateDay = createAsyncThunk(
  'activity/calendar/updateDay',
  async (data: {
    userId: string
    date: string
    data: { title?: string | null; voiceover?: string | null }
  }) => {
    return UserService.updateCoachPlanDay(data.userId, data.date, data.data)
  }
)

export const reorderDayActivityList = createAsyncThunk(
  'activity/calendar/reorderActivityList',
  async (data: {
    userId: string
    date: string
    activities: ActivityApi.ReorderActivities.Request
  }) => {
    return UserService.reorderActivities(
      data.userId,
      data.date,
      data.activities
    )
  }
)

export const deleteActivities = createAsyncThunk(
  'activity/calendar/activities/delete',
  async (data: { userId: string; activities: CoachPlan.RemoveActivity[] }) => {
    return UserService.deleteActivities(data.userId, data.activities)
  }
)

export const deleteDays = createAsyncThunk(
  'activity/calendar/days/delete',
  async (data: { userId: string; days: string[] }) => {
    return UserService.deleteDays(data.userId, { day_ids: data.days })
  }
)

export const fetchActivitySummaries = createAsyncThunk<
  FitnessDataApi.ActivitySummaries.Response,
  {
    userId: string
    fromDate: string
    toDate: string
  },
  {
    dispatch: AppDispatch
    state: AppState
  }
>(
  'activity/getActivitySummaries',
  async (data: { userId: string; fromDate: string; toDate: string }) => {
    return UserService.getActivitySummaries(
      data.userId,
      data.fromDate,
      data.toDate
    )
  }
)

export const moveActivities = createAsyncThunk(
  'activities/move',
  async (data: {
    userId: string
    copiedActivities: CoachPlan.ActivityCopy[]
    targetDate: string
  }) => {
    if (
      !data.copiedActivities.every(
        (copiedActivity) => copiedActivity.sourceUserId === data.userId
      )
    ) {
      throw new Error('Cannot move activities from different users')
    }
    if (data.copiedActivities.length > 0) {
      const requestData: ActivityApi.DuplicateActivities.Request = {
        activities: data.copiedActivities.map((copiedActivity) => ({
          source_activity_id: copiedActivity.id,
          source_date: copiedActivity.coach_plan_day_id,
        })),
        source_user_id: data.copiedActivities[0].sourceUserId,
        target_date: data.targetDate,
      }
      return UserService.moveActivities(data.userId, requestData)
    }
    throw new Error('No activities to move')
  }
)

export const duplicateActivities = createAsyncThunk(
  'activities/duplicate',
  async (data: {
    userId: string
    copiedActivities: CoachPlan.ActivityCopy[]
    targetDate: string
  }) => {
    if (data.copiedActivities.length > 0) {
      const requestData: ActivityApi.DuplicateActivities.Request = {
        activities: data.copiedActivities.map((copiedActivity) => ({
          source_activity_id: copiedActivity.id,
          source_date: copiedActivity.coach_plan_day_id,
        })),
        source_user_id: data.copiedActivities[0].sourceUserId,
        target_date: data.targetDate,
      }
      return UserService.duplicateActivities(data.userId, requestData)
    }
    throw new Error('No activities to duplicate')
  }
)

export const duplicateDays = createAsyncThunk(
  'days/duplicate',
  async (data: {
    userId: string
    copiedDays: CoachPlan.DayCopy[]
    targetDate: string
  }) => {
    if (data.copiedDays.length > 0) {
      const requestData: ActivityApi.DuplicateDays.Request = {
        source_day_ids: data.copiedDays.map((copiedDate) => copiedDate.id),
        source_user_id: data.copiedDays[0].sourceUserId,
        target_date: data.targetDate,
      }
      return UserService.duplicateDays(data.userId, requestData)
    }
    throw new Error('No days to duplicate')
  }
)

export const moveDays = createAsyncThunk(
  'days/move',
  async (data: {
    userId: string
    copiedDays: CoachPlan.DayCopy[]
    targetDate: string
  }) => {
    if (
      !data.copiedDays.every(
        (copiedDay) => copiedDay.sourceUserId === data.userId
      )
    ) {
      throw new Error('Cannot move days from different users')
    }
    if (data.copiedDays.length > 0) {
      const requestData: ActivityApi.DuplicateDays.Request = {
        source_day_ids: data.copiedDays.map((copiedDate) => copiedDate.id),
        source_user_id: data.copiedDays[0].sourceUserId,
        target_date: data.targetDate,
      }
      return UserService.moveDays(data.userId, requestData)
    }
    throw new Error('No days to duplicate')
  }
)

export const fetchExerciseHistoryStatistics = createAsyncThunk<
  ExerciseHistory.AllStatistics,
  { userId: string; exerciseCode: string; fromDate?: string; toDate: string },
  { dispatch: AppDispatch; state: AppState }
>('exercise/history/fetchStatistics', async (data) => {
  return UserService.getExerciseHistoryStatistics(
    data.userId,
    data.exerciseCode,
    data.toDate,
    data.fromDate
  )
})

export const fetchExerciseUsageHistory = createAsyncThunk<
  ExerciseHistory.UsageFrequency,
  {
    userId: string
    activityType: string
    coachPlanDayId: string
    position?: number | null
  },
  { dispatch: AppDispatch; state: AppState }
>('exercise/history/fetchUsageFrequency', async (data) => {
  return UserService.getExercisesUsageFrequency(
    data.userId,
    data.activityType,
    data.coachPlanDayId,
    data.position
  )
})

export const fetchExercisesDislikes = createAsyncThunk<
  ExerciseHistory.Dislikes,
  {
    userId: string
    activityType: string
  },
  { dispatch: AppDispatch; state: AppState }
>('exercise/history/fetchDislikes', async (data) => {
  return UserService.getExercisesDislikes(data.userId, data.activityType)
})

export const fetchExerciseHistory = createAsyncThunk<
  ExerciseHistory.History,
  { userId: string; exerciseCode: string },
  { dispatch: AppDispatch; state: AppState }
>('exercise/history/fetchHistory', async (data) => {
  return UserService.getExerciseHistory(data.userId, data.exerciseCode)
})

const activityCalendarSlice = createSlice({
  name: 'activityCalendar',
  initialState,
  reducers: {
    setEmptyDaysByRange: (
      state,
      {
        payload,
      }: PayloadAction<{ emptyDays: WithId<HumanCoaching.Day>['id'][] }>
    ) => {
      state.days = payload.emptyDays.map((day) => {
        return {
          id: day,
          title: null,
          activities: [],
        } as WithId<HumanCoaching.Day>
      })
    },
    resetActivityCalendar: () => initialState,
    resetCopiedAndSelected: resetCopiedAndSelectedState,
    closeDayOverview: (state) => {
      state.dayOverview.isOpen = false
    },
    openDayOverview: (
      state,
      {
        payload,
      }: PayloadAction<{ coachPlanDayId: WithId<HumanCoaching.Day>['id'] }>
    ) => {
      state.dayOverview.isOpen = true
      state.dayOverview.coachPlanDayId = payload.coachPlanDayId
    },
    closeExerciseHistoryDetail: (state) => {
      state.exerciseHistoryDetail.isOpen = false
    },
    openExerciseHistoryDetail: (
      state,
      {
        payload,
      }: PayloadAction<{
        combinedExerciseType: HumanCoaching.CombinedExerciseType
        exerciseCode: string
      }>
    ) => {
      state.exerciseHistoryDetail.isOpen = true
      state.exerciseHistoryDetail.combinedExerciseType =
        payload.combinedExerciseType

      if (state.exerciseHistoryDetail.exerciseCode !== payload.exerciseCode) {
        state.exerciseHistoryDetail.exerciseCode = payload.exerciseCode
        state.exerciseHistoryDetail.exercise = null
        state.exerciseHistoryDetail.sessions = null
      }
    },
    resetSelected: (state) => {
      state.selectedDays = []
      state.selectedActivities = []
    },
    setSelectedDaysInRange: (
      state: ActivityCalendarState,
      {
        payload,
      }: PayloadAction<{
        firstDay: WithId<HumanCoaching.Day>['id']
        lastDay: WithId<HumanCoaching.Day>['id']
      }>
    ) => {
      // Remove first day added by setSelectDay
      state.selectedDays.shift()

      const datesInRange = getDatesWithinRange(
        payload.firstDay,
        payload.lastDay
      )

      const selectedDayIds = [...state.days]
        .filter((day) => datesInRange.includes(day.id))
        .sort((a, b) => {
          return a.id.localeCompare(b.id)
        })
        .map((day) => day.id)

      state.selectedDays = selectedDayIds
    },
    setSelectedDayById: (
      state: ActivityCalendarState,
      {
        payload,
      }: PayloadAction<{ coachPlanDayId: WithId<HumanCoaching.Day>['id'] }>
    ) => {
      state.selectedActivities = []
      state.selectedDays = [payload.coachPlanDayId]
    },
    setSelectedDay: (
      state: ActivityCalendarState,
      {
        payload,
      }: PayloadAction<{
        isMultipleSelect: boolean
        coachPlanDayId: WithId<HumanCoaching.Day>['id']
      }>
    ) => {
      state.selectedActivities = []

      if (payload.isMultipleSelect) {
        const isDaySelected = state.selectedDays.find(
          (selectedDayCoachPlanDayId) =>
            selectedDayCoachPlanDayId === payload.coachPlanDayId
        )

        if (isDaySelected) {
          state.selectedDays = state.selectedDays.filter(
            (selectedDayCoachPlanDayId) =>
              selectedDayCoachPlanDayId !== payload.coachPlanDayId
          )
        } else {
          state.selectedDays = [...state.selectedDays, payload.coachPlanDayId]
        }
      } else {
        state.selectedDays = [payload.coachPlanDayId]
      }
    },
    setSelectedActivity: (state, { payload }) => {
      state.selectedDays = []

      if (payload.isMultipleSelect) {
        const isActivitySelected = state.selectedActivities.find(
          (activity) => activity.id === payload.activity.id
        )

        if (isActivitySelected) {
          state.selectedActivities = state.selectedActivities.filter(
            (activity) => activity.id !== payload.activity.id
          )
        } else {
          state.selectedActivities = [
            ...state.selectedActivities,
            payload.activity,
          ]
        }
      } else {
        state.selectedActivities = [payload.activity]
        state.selectedDays = []
      }
    },

    setCopiedDays: (
      state: ActivityCalendarState,
      { payload }: PayloadAction<{ copiedDays: CoachPlan.DayCopy[] }>
    ) => {
      state.copiedDays = payload.copiedDays || []
      state.copiedActivities = []
      state.selectedDays = []
      state.selectedActivities = []
    },
    setCopiedDaysFromLocalStorage: (
      state: ActivityCalendarState,
      { payload }: PayloadAction<{ copiedDays: CoachPlan.DayCopy[] }>
    ) => {
      state.copiedDays = payload.copiedDays || []
      state.copiedActivities = []
    },
    setCopiedActivities: (
      state,
      {
        payload,
      }: PayloadAction<{
        sourceUserId: string
        selectedActivities: WithId<HumanCoaching.Activity>[]
        isCut: boolean
      }>
    ) => {
      state.copiedActivities = []

      if (payload.selectedActivities?.length > 0) {
        state.copiedActivities = [...payload.selectedActivities]
          .sort((a, b) => a.position - b.position)
          .map((activity: WithId<HumanCoaching.Activity>) => {
            return {
              ...activity,
              sourceUserId: payload.sourceUserId,
              isCut: payload.isCut,
            }
          })
      }

      state.copiedDays = []
      state.selectedDays = []
      state.selectedActivities = []
    },
    setCopiedActivitiesFromLocalStorage: (
      state,
      {
        payload,
      }: PayloadAction<{
        sourceUserId: string
        selectedActivities: WithId<HumanCoaching.Activity>[]
        isCut: boolean
      }>
    ) => {
      state.copiedActivities = []

      if (payload.selectedActivities?.length > 0) {
        state.copiedActivities = [...payload.selectedActivities]
          .sort((a, b) => a.position - b.position)
          .map((activity: WithId<HumanCoaching.Activity>) => {
            return {
              ...activity,
              sourceUserId: payload.sourceUserId,
              isCut: payload.isCut,
            }
          })
      }

      state.copiedDays = []
    },
    setVisibleDate: (
      state: ActivityCalendarState,
      { payload }: PayloadAction<{ month: number; year: number; day?: number }>
    ) => {
      state.visibleDate = payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchDays.pending, (state) => {
      state.loading = true
    })
    builder.addCase(fetchDays.fulfilled, (state, action) => {
      const updatedDays = state.days.map((day) => {
        const payloadItem = action.payload.find((item) => item.id === day.id)

        if (payloadItem) {
          return {
            ...payloadItem,
            id: day.id,
          } as WithId<HumanCoaching.Day>
        }

        return {
          id: day.id,
          title: null,
          activities: [],
        } as WithId<HumanCoaching.Day>
      })

      state.days = updatedDays
      state.loading = false
    })
    builder.addCase(fetchDays.rejected, (state) => {
      state.loading = false
    })
    builder.addCase(fetchActivitySummaries.pending, (state) => {
      state.loading = true
    })
    builder.addCase(fetchActivitySummaries.fulfilled, (state, action) => {
      state.activitySummaries = action.payload
      state.loading = false
    })
    builder.addCase(fetchActivitySummaries.rejected, (state) => {
      state.loading = false
    })
    builder.addCase(fetchAppleHealthActivitiesData.pending, (state) => {
      state.loading = true
    })
    builder.addCase(
      fetchAppleHealthActivitiesData.fulfilled,
      (state, action) => {
        state.nonFitifyActivities = action.payload
        state.loading = false
      }
    )
    builder.addCase(fetchAppleHealthActivitiesData.rejected, (state) => {
      state.loading = false
    })
    builder.addCase(deleteActivities.pending, (state, action) => {
      const days = getUniqueArray(
        action.meta.arg.activities.map((activity) => activity.date)
      )
      const requestId = action.meta.requestId
      state.pendingDaysRequests[requestId] = days
    })
    builder.addCase(deleteActivities.fulfilled, (state, action) => {
      const deletedActivities = action.meta.arg.activities

      deletedActivities.forEach((deletedActivity) => {
        const targetDay = state.days.find((day) => {
          return day.id === deletedActivity.date
        })
        if (targetDay) {
          const index = targetDay.activities.findIndex(
            (i) => i.id === deletedActivity.id
          )
          targetDay.activities.splice(index, 1)
        }
      })
    })
    builder.addCase(moveActivities.pending, (state, action) => {
      const days = getUniqueArray([
        action.meta.arg.targetDate,
        ...action.meta.arg.copiedActivities.map(
          (activity) => activity.coach_plan_day_id
        ),
      ])
      const requestId = action.meta.requestId
      state.pendingDaysRequests[requestId] = days
    })
    builder.addCase(
      moveActivities.fulfilled,
      (
        state,
        action: PayloadAction<
          WithId<HumanCoaching.Activity>[],
          string,
          {
            arg: {
              copiedActivities: CoachPlan.ActivityCopy[]
              targetDate: string
            }
            requestId: string
            requestStatus: 'fulfilled'
          }
        >
      ) => {
        const cutActivities = action.payload
        const movedActivities = action.meta.arg.copiedActivities

        duplicateDaysAndActivities(state.days, cutActivities)

        movedActivities.forEach((moveActivity) => {
          const sourceDay = state.days.find((day) => {
            return day.id === moveActivity.coach_plan_day_id
          })
          if (sourceDay) {
            const index = sourceDay.activities.findIndex(
              (activity) => activity.id === moveActivity.id
            )
            if (index !== -1) {
              sourceDay.activities.splice(index, 1)
            }
          }
        })
      }
    )
    builder.addCase(moveDays.pending, (state, action) => {
      const sourceDays = action.meta.arg.copiedDays.map((day) => day.id)
      const targetDays = getTargetDaysWithOffset(
        action.meta.arg.targetDate,
        sourceDays
      )
      const requestId = action.meta.requestId
      state.pendingDaysRequests[requestId] = getUniqueArray([
        ...sourceDays,
        ...targetDays,
      ])
    })
    builder.addCase(moveDays.fulfilled, (state, action) => {
      const updatedDays = state.days.map((day) => {
        const updatedDay = action.payload.find((item) => item.id === day.id)
        return updatedDay || day
      })

      state.days = updatedDays
    })
    builder.addCase(deleteDays.pending, (state, action) => {
      const days = getUniqueArray(action.meta.arg.days)
      const requestId = action.meta.requestId
      state.pendingDaysRequests[requestId] = days
    })
    builder.addCase(deleteDays.fulfilled, (state, action) => {
      const deletedDayIds = action.meta.arg.days

      const updatedDays = state.days.map((day) => {
        if (deletedDayIds.includes(day.id)) {
          return {
            id: day.id,
            title: null,
            activities: [],
          } as WithId<HumanCoaching.Day>
        }
        return day
      })

      state.days = updatedDays
    })
    builder.addCase(duplicateActivities.pending, (state, action) => {
      const requestId = action.meta.requestId
      state.pendingDaysRequests[requestId] = [action.meta.arg.targetDate]
    })
    builder.addCase(duplicateActivities.fulfilled, (state, action) => {
      duplicateDaysAndActivities(state.days, action.payload)
    })
    builder.addCase(duplicateDays.pending, (state, action) => {
      const days = getTargetDaysWithOffset(
        action.meta.arg.targetDate,
        action.meta.arg.copiedDays.map((day) => day.id)
      )
      const requestId = action.meta.requestId
      state.pendingDaysRequests[requestId] = days
    })
    builder.addCase(duplicateDays.fulfilled, (state, action) => {
      const updatedDays = state.days.map((day) => {
        const updatedDay = action.payload.find((item) => item.id === day.id)
        return updatedDay || day
      })

      state.days = updatedDays
    })
    builder.addCase(updateDay.pending, (state) => {
      state.loading = true
    })
    builder.addCase(updateDay.fulfilled, (state, action) => {
      const date = action.meta.arg.date
      const targetDay = state.days.find((day) => {
        return day.id === date
      })

      if (targetDay) {
        if (
          Object.prototype.hasOwnProperty.call(action.meta.arg.data, 'title')
        ) {
          targetDay.title = action.meta.arg.data.title ?? undefined
        }
        if (
          Object.prototype.hasOwnProperty.call(
            action.meta.arg.data,
            'voiceover'
          )
        ) {
          targetDay.voiceover = action.meta.arg.data.voiceover ?? undefined
        }
      } else {
        state.days.push({
          id: date,
          activities: [],
          title: action.meta.arg.data.title ?? undefined,
          voiceover: action.meta.arg.data.voiceover ?? undefined,
        })
      }

      state.loading = false
    })
    builder.addCase(updateDay.rejected, (state) => {
      state.loading = false
    })
    builder.addCase(reorderDayActivityList.pending, (state) => {
      state.loading = true
    })
    builder.addCase(reorderDayActivityList.fulfilled, (state, action) => {
      const day = state.days.find((day) => day.id === action.meta.arg.date)

      if (day) {
        const updatedActivities = day.activities.map((activity) => {
          const updatedActivity = action.meta.arg.activities.find(
            (item) => item.id === activity.id
          )
          return updatedActivity
            ? { ...activity, ...updatedActivity }
            : activity
        })
        day.activities = updatedActivities
      }

      state.loading = false
    })
    builder.addCase(reorderDayActivityList.rejected, (state) => {
      state.loading = false
    })
    builder.addCase(
      fetchDayOverviewHealthData.pending,
      (state: ActivityCalendarState) => {
        state.loading = false
      }
    )
    builder.addCase(fetchDayOverviewHealthData.fulfilled, (state, action) => {
      state.dayOverview.healthData = action.payload
      state.loading = false
    })
    builder.addCase(
      fetchDayOverviewHealthData.rejected,
      (
        state: ActivityCalendarState,
        action: PayloadAction<
          unknown,
          string,
          | ({
              arg: { userId: string; dayId: string }
              requestId: string
              requestStatus: 'rejected'
              aborted: boolean
              condition: boolean
            } & { rejectedWithValue: true })
          | ({
              arg: { userId: string; dayId: string }
              requestId: string
              requestStatus: 'rejected'
              aborted: boolean
              condition: boolean
            } & { rejectedWithValue: false }),
          SerializedError
        >
      ) => {
        state.error =
          action.error.message !== undefined ? action.error.message : null
        state.loading = false
      }
    )
    builder.addCase(
      fetchDayOverviewSessionsAchievements.fulfilled,
      (
        state: ActivityCalendarState,
        action: PayloadAction<Record<string, number>>
      ) => {
        state.dayOverview.sessionsAchievements = {
          ...state.dayOverview.sessionsAchievements,
          ...action.payload,
        }
      }
    )
    builder.addCase(
      fetchExerciseHistoryStatistics.fulfilled,
      (state, action) => {
        state.exerciseHistoryStatistics = [
          ...state.exerciseHistoryStatistics,
          {
            exerciseCode: action.meta.arg.exerciseCode,
            fromDate: action.meta.arg.fromDate || null,
            toDate: action.meta.arg.toDate,
            statistics: action.payload,
          },
        ]
      }
    )
    builder.addCase(fetchExerciseUsageHistory.pending, (state) => {
      state.exercisesUsageFrequency = {
        error: null,
        loading: true,
        exercises: {},
        activityType: null,
        dayId: null,
        position: null,
      }
    })
    builder.addCase(fetchExerciseUsageHistory.fulfilled, (state, action) => {
      state.exercisesUsageFrequency = {
        error: null,
        loading: false,
        exercises: action.payload,
        activityType: action.meta.arg
          .activityType as HumanCoaching.ActivityType,
        dayId: action.meta.arg.coachPlanDayId,
        position: action.meta.arg.position ?? null,
      }
    })
    builder.addCase(fetchExerciseUsageHistory.rejected, (state, action) => {
      state.exercisesUsageFrequency = {
        error: action.error.message || 'Something went wrong',
        loading: false,
        exercises: {},
        activityType: null,
        dayId: null,
        position: null,
      }
    })
    builder.addCase(fetchExercisesDislikes.pending, (state) => {
      state.exercisesDislikes = {
        error: null,
        loading: true,
        exercises: {},
        activityType: null,
      }
    })
    builder.addCase(fetchExercisesDislikes.fulfilled, (state, action) => {
      state.exercisesDislikes = {
        error: null,
        loading: false,
        exercises: action.payload,
        activityType: action.meta.arg
          .activityType as HumanCoaching.ActivityType,
      }
    })
    builder.addCase(fetchExercisesDislikes.rejected, (state, action) => {
      state.exercisesDislikes = {
        error: action.error.message || 'Something went wrong',
        loading: false,
        exercises: {},
        activityType: null,
      }
    })
    builder.addCase(fetchExerciseHistory.fulfilled, (state, action) => {
      state.exerciseHistoryDetail.exercise = action.payload.exercise
      state.exerciseHistoryDetail.sessions = action.payload.sessions
    })
    builder.addCase(fetchExerciseHistory.rejected, (state) => {
      state.exerciseHistoryDetail.exercise = null
      state.exerciseHistoryDetail.sessions = null
    })

    builder.addMatcher(
      isAnyOf(createActivity.pending, updateActivity.pending),
      (state) => {
        state.loading = true
      }
    )

    builder.addMatcher(
      isAnyOf(createActivity.fulfilled, updateActivity.fulfilled),
      (state, action) => {
        const newActivity = {
          ...action.payload,
          coach_plan_day_id: action.meta.arg.day.id,
          calendar_day_id: action.meta.arg.day.id,
        }
        const targetDay = state.days.find(
          (day) => day.id === action.meta.arg.day.id
        )
        if (!targetDay) {
          if (action.meta.arg.day.activities) {
            state.days.push({
              id: action.meta.arg.day.id,
              title: null,
              activities: [...action.meta.arg.day.activities, newActivity],
            } as WithId<HumanCoaching.Day>)
          } else {
            state.days.push({
              id: action.meta.arg.day.id,
              title: null,
              activities: [newActivity],
            } as WithId<HumanCoaching.Day>)
          }
        } else {
          const oldActivity = targetDay.activities.find(
            ({ id }) => id === newActivity.id
          )
          if (oldActivity) {
            const oldActivityIndex = targetDay.activities.indexOf(oldActivity)
            if (oldActivityIndex > -1) {
              targetDay.activities.splice(oldActivityIndex, 1)
            }
            targetDay.activities.push(newActivity)
          } else {
            targetDay.activities.push(newActivity)
          }
        }

        state.loading = false
      }
    )
    builder.addMatcher(
      isAnyOf(createActivity.rejected, updateActivity.rejected),
      (state) => {
        state.loading = false
      }
    )
    builder.addMatcher(
      isAnyOf(
        deleteActivities.fulfilled,
        deleteActivities.rejected,
        duplicateActivities.fulfilled,
        duplicateActivities.rejected,
        moveActivities.fulfilled,
        moveActivities.rejected,
        deleteDays.fulfilled,
        deleteDays.rejected,
        duplicateDays.fulfilled,
        duplicateDays.rejected,
        moveDays.fulfilled,
        moveDays.rejected
      ),
      (state, action) => {
        state.pendingDaysRequests = removePendingRequest(
          state.pendingDaysRequests,
          action.meta.requestId
        )
        state.loading = false
      }
    )
    builder.addMatcher(
      isAnyOf(
        deleteActivities.pending,
        duplicateActivities.pending,
        moveActivities.pending,
        deleteDays.pending,
        duplicateDays.pending,
        moveDays.pending
      ),
      (state) => {
        resetCopiedAndSelectedState(state)
        state.loading = true
      }
    )
  },
})

export const {
  closeDayOverview,
  openDayOverview,
  closeExerciseHistoryDetail,
  openExerciseHistoryDetail,
  resetActivityCalendar,
  resetCopiedAndSelected,
  resetSelected,
  setSelectedDaysInRange,
  setSelectedDayById,
  setSelectedDay,
  setEmptyDaysByRange,
  setSelectedActivity,
  setCopiedActivities,
  setCopiedActivitiesFromLocalStorage,
  setCopiedDays,
  setCopiedDaysFromLocalStorage,
  setVisibleDate,
} = activityCalendarSlice.actions

export const activityCalendarSelector = (state: AppState) =>
  state.activityCalendar
export const activityCalendarDayOverviewSelector = (state: AppState) =>
  state.activityCalendar.dayOverview
export const activityCalendarExerciseHistoryDetailSelector = (
  state: AppState
) => state.activityCalendar.exerciseHistoryDetail
export const activityCalendarUsageFrequencySelector = (state: AppState) =>
  state.activityCalendar.exercisesUsageFrequency
export const activityCalendarExercisesDislikesSelector = (state: AppState) =>
  state.activityCalendar.exercisesDislikes

export default activityCalendarSlice.reducer
