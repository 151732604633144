import { HTMLAttributes, MouseEvent } from 'react'

import { StyledOverlay } from './Overlay.Styled'
import { OverlayProps } from './Overlay.Types'

type Props = OverlayProps & HTMLAttributes<HTMLDivElement>

export const Overlay = ({
  children,
  duration,
  overlayOpacity,
  padding,
  preventClickEvents = false,
  show,
  showOverlay,
  zIndex,
  ...props
}: Props) => {
  const handlePreventClick = (event: MouseEvent) => {
    event.preventDefault()
    event.stopPropagation()
  }

  return (
    <StyledOverlay
      {...props}
      $duration={duration}
      $overlayOpacity={overlayOpacity}
      $padding={padding}
      $show={show}
      $showOverlay={showOverlay}
      $zIndex={zIndex}
      {...(preventClickEvents && {
        onClick: handlePreventClick,
        onContextMenu: handlePreventClick,
        onDoubleClick: handlePreventClick,
      })}
    >
      {children}
    </StyledOverlay>
  )
}
