import { StyledIcon } from '../Icon.Styled'
import { IconProps } from '../Icon.Types'

export const ArrowSmRightIcon = (props: IconProps) => (
  <StyledIcon
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10 17.4139L8.586 15.9999L12.586 11.9999L8.586 7.99994L10 6.58594L14.707 11.2929C15.098 11.6839 15.098 12.3159 14.707 12.7069L10 17.4139Z"
      fill="currentColor"
    />
  </StyledIcon>
)
