import styled, { css, keyframes } from 'styled-components'

import { addAlpha, colors } from '../theme/colors'

import { StyledOverlayProps } from './Overlay.Types'

export const defaultFadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`

export const defaultFadeOut = keyframes`
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
`

export const StyledOverlay = styled.div<StyledOverlayProps>`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100dvh;

  animation-name: ${({ $show }) => ($show ? defaultFadeIn : defaultFadeOut)};
  animation-duration: ${({ $duration }) => $duration || 500}ms;

  ${({ $overlayOpacity, $padding, $showOverlay }) => {
    if ($showOverlay) {
      return css`
        padding: ${$padding || '2rem'};
        background-color: ${addAlpha(colors.Black, $overlayOpacity || 0.3)};
      `
    } else {
      return css`
        padding: 0;
        background-color: ${addAlpha(colors.Black, 0)};
      `
    }
  }};

  ${({ $zIndex }) =>
    $zIndex !== undefined &&
    css`
      z-index: ${$zIndex};
    `};

  @media (max-width: 768px) {
    padding: 0;
  }
`
