import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { CoachHelper } from 'fitify-types/src/types/coach-admin-api'
import { HumanCoaching } from 'fitify-types/src/types/human-coaching'

import { AiService } from '@/api/services/AiService'
import {
  kebabCase,
  parseMarkdownTable,
} from '@/components/user-profile/pages/ai-helper/ChatResponseHelper/ChatResponseHelper.Utils'

import { AppDispatch, AppState } from '../store'

export type ResponseFeedbackItem = {
  interactionStyle: CoachHelper.Suggestion.PromptInteractionStyle
  subResponseId?: string
  type?: CoachHelper.Suggestion.Feedback.FeedbackType
  feedbackMessage: string | null
  feedbackId: string | null
  isSent: boolean
}

type ResponseFeedback = ResponseFeedbackItem[]
interface AiHelperState {
  timeAgo: string | null
  tags: string[]
  suggestionTypes: CoachHelper.Suggestion.PromptInteractionStyle[]
  messages: HumanCoaching.HcMessage[]
  responses: Partial<
    Record<
      CoachHelper.Suggestion.PromptInteractionStyle,
      CoachHelper.Suggestion.Response['data']
    >
  >
  feedbacks: ResponseFeedback
  areSuggestionDataLoaded: boolean
}

const initialState: AiHelperState = {
  timeAgo: null,
  tags: [],
  suggestionTypes: [],
  messages: [],
  responses: {},
  feedbacks: [],
  areSuggestionDataLoaded: false,
}

export const createChainFeedback = createAsyncThunk<
  CoachHelper.Suggestion.Feedback.Create.Response,
  CoachHelper.Suggestion.Feedback.Create.Request,
  {
    dispatch: AppDispatch
    state: AppState
  }
>('aiHelper/createChainFeedback', async (data) => {
  return await AiService.createFeedback(data)
})

export const updateChainFeedback = createAsyncThunk<
  CoachHelper.Suggestion.Feedback.Update.Response,
  CoachHelper.Suggestion.Feedback.Update.Request,
  {
    dispatch: AppDispatch
    state: AppState
  }
>('aiHelper/updateChainFeedback', async (data) => {
  return await AiService.updateFeedback({
    feedbackId: data.feedbackId,
    opts: data.opts,
  })
})

export const deleteChainFeedback = createAsyncThunk<
  void,
  { feedbackId: string },
  {
    dispatch: AppDispatch
    state: AppState
  }
>('aiHelper/deleteChainFeedback', async (data: { feedbackId: string }) => {
  return await AiService.deleteFeedback(data.feedbackId)
})

export const getAiSuggestion = createAsyncThunk<
  CoachHelper.Suggestion.Response,
  CoachHelper.Suggestion.Request,
  {
    dispatch: AppDispatch
    state: AppState
  }
>('aiHelper/getAiSuggestion', async (data) => {
  return await AiService.getSuggestion({
    id: data.id,
    userId: data.userId,
  })
})

export const fetchSuggestionData = createAsyncThunk<
  CoachHelper.Suggestion.InitialData.Response,
  CoachHelper.Suggestion.InitialData.Request,
  {
    dispatch: AppDispatch
    state: AppState
  }
>('aiHelper/fetchSuggestionData', async (data) => {
  return await AiService.getAiSuggestionData({
    userId: data.userId,
  })
})

const aiHelperSlice = createSlice({
  name: 'aiHelper',
  initialState,
  reducers: {
    regenerateSuggestions: (state) => {
      state.responses = {}
      state.feedbacks = []
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchSuggestionData.pending, (state) => {
      state.areSuggestionDataLoaded = false
    })
    builder.addCase(fetchSuggestionData.fulfilled, (state, action) => {
      state.messages = action.payload.data.messages
      state.suggestionTypes = action.payload.data.suggestion.types
      state.timeAgo = action.payload.data.time_ago
      state.tags = action.payload.data.tags
      state.areSuggestionDataLoaded = true
    })
    builder.addCase(fetchSuggestionData.rejected, (state) => {
      state.areSuggestionDataLoaded = true
    })
    builder.addCase(getAiSuggestion.rejected, (state) => {
      state.areSuggestionDataLoaded = true
    })
    builder.addCase(getAiSuggestion.fulfilled, (state, action) => {
      if (action.payload.data.type === 'chat_helper_category') {
        const data = action.payload.data.text
          ? parseMarkdownTable<{
              category: string
              score: string
              reason: string
            }>(action.payload.data.text)
          : undefined

        if (data) {
          state.feedbacks = [
            ...state.feedbacks,
            ...data.map(
              (item: { category: string; score: string; reason: string }) => ({
                subResponseId: kebabCase(item.category),
                feedbackId: null,
                feedbackMessage: null,
                isSent: false,
                interactionStyle: action.payload.data.id,
                componentType: action.payload.data.type,
              })
            ),
          ]
        }
      } else {
        state.feedbacks = [
          ...state.feedbacks,
          {
            feedbackId: null,
            feedbackMessage: null,
            isSent: false,
            interactionStyle: action.payload.data.id,
          },
        ]
      }

      state.responses[action.meta.arg.id] = {
        ...action.payload.data,
      }
    })
    builder.addCase(createChainFeedback.fulfilled, (state, action) => {
      state.feedbacks = state.feedbacks.map((item: ResponseFeedbackItem) => {
        if (action.meta.arg.feedbackId) {
          if (item.subResponseId === action.meta.arg.feedbackId) {
            return {
              ...item,
              feedbackId: action.payload.id,
              feedbackMessage: action.payload.comment,
              type: action.meta.arg.feedbackType,
              isSent: true,
            }
          }
        } else {
          if (item.interactionStyle === action.meta.arg.key) {
            return {
              ...item,
              feedbackId: action.payload.id,
              feedbackMessage: action.payload.comment,
              type: action.meta.arg.feedbackType,
              isSent: true,
            }
          }
        }

        return item
      })
    })
    builder.addCase(updateChainFeedback.fulfilled, (state, action) => {
      state.feedbacks = state.feedbacks.map((item: ResponseFeedbackItem) => {
        if (item.feedbackId === action.meta.arg.feedbackId) {
          return {
            ...item,
            feedbackMessage: action.meta.arg.opts.comment as string,
            isSent: true,
          }
        }

        return item
      })
    })
    builder.addCase(deleteChainFeedback.fulfilled, (state, action) => {
      state.feedbacks = state.feedbacks.map((item: ResponseFeedbackItem) => {
        if (item.feedbackId === action.meta.arg.feedbackId) {
          return {
            ...item,
            feedbackMessage: null,
            feedbackId: null,
            isSent: false,
          }
        }

        return item
      })
    })
  },
})

export const { regenerateSuggestions } = aiHelperSlice.actions

export const aiHelperSelector = (state: AppState) => state.aiHelper
export default aiHelperSlice.reducer
