import { StyledIcon } from '../Icon.Styled'
import { IconProps } from '../Icon.Types'

export const IAddIcon = (props: IconProps) => (
  <StyledIcon
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11 8.5V11H6V13H11V18H13V13H18V11H13V6H11V8.5Z"
      fill="currentColor"
    />
  </StyledIcon>
)
