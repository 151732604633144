import '../utils/i18n'
import '@/theme/Fonts.css'
import 'react-tooltip/dist/react-tooltip.css'
import 'react-toastify/dist/ReactToastify.css'

import { initializeApp } from 'firebase/app'
import { connectAuthEmulator, getAuth } from 'firebase/auth'
import { connectFunctionsEmulator, getFunctions } from 'firebase/functions'
import { getPerformance } from 'firebase/performance'
import { useSmartlook } from 'fitify-ui'
import { isNullOrUndefined } from 'fitify-utils/src/properties'
import { AppProps } from 'next/app'
import { useEffect } from 'react'
import TagManager from 'react-gtm-module'
import { SkeletonTheme } from 'react-loading-skeleton'
import { Provider } from 'react-redux'
import { Tooltip } from 'react-tooltip'

import withErrorBoundary from '@/components/core/ErrorBoundary/withErrorBoundary'
import { ToastContainer } from '@/components/core/Toast/ToastContainer'
import { KeyEventEmitter } from '@/components/ui/KeyEventHandler/KeyEventEmitter'
import { AuthProvider } from '@/hooks/useAuthProvider'
import store from '@/store/store'
import { GlobalStyle } from '@/theme/globalStyle'
import { getErrorHandler } from '@/utils/errorHandler'

const app = initializeApp({
  apiKey: process.env.NEXT_PUBLIC_API_KEY,
  authDomain: process.env.NEXT_PUBLIC_AUTH_DOMAIN,
  databaseURL: process.env.NEXT_PUBLIC_DATABASE_URL,
  projectId: process.env.NEXT_PUBLIC_PROJECT_ID,
  storageBucket: process.env.NEXT_PUBLIC_STORAGE_BUCKET,
  messagingSenderId: process.env.NEXT_PUBLIC_MESSAGING_SENDER_ID,
  appId: process.env.NEXT_PUBLIC_APP_ID,
  measurementId: process.env.NEXT_PUBLIC_MEASUREMENT_ID,
})

const firebaseAuth = getAuth()

if (process.env.NEXT_PUBLIC_FIREBASE_AUTH_EMULATOR_URL) {
  connectAuthEmulator(
    firebaseAuth,
    process.env.NEXT_PUBLIC_FIREBASE_AUTH_EMULATOR_URL
  )
}

if (process.env.NEXT_PUBLIC_FUNCTIONS_EMULATOR_PORT) {
  connectFunctionsEmulator(
    getFunctions(),
    'localhost',
    parseInt(process.env.NEXT_PUBLIC_FUNCTIONS_EMULATOR_PORT)
  )
}

const FitifyCoachAdmin = (props: AppProps) => {
  const isSmartlookEnabled = !isNullOrUndefined(
    process.env.NEXT_PUBLIC_SMARTLOOK_API_KEY
  )

  useSmartlook(isSmartlookEnabled)

  useEffect(() => {
    TagManager.initialize({
      gtmId: process.env.NEXT_PUBLIC_GTM_ID as string,
    })
    if (process.env.NODE_ENV !== 'development') {
      window.onerror = (
        _msg: string | Event,
        _file?: string,
        _line?: number,
        _col?: number,
        error?: Error
      ) => {
        if (error) {
          if (firebaseAuth.currentUser) {
            getErrorHandler()?.setUser(firebaseAuth.currentUser.uid)
          }
          getErrorHandler()?.report(error)
        }
      }

      getPerformance(app)
    }
  }, [])

  const { Component, pageProps } = props

  return (
    <SkeletonTheme
      baseColor="#E5EBEE"
      highlightColor="#FFFFFF"
      borderRadius="0.250rem"
      duration={1}
      enableAnimation
    >
      <GlobalStyle />
      <Provider store={store}>
        <KeyEventEmitter />
        <AuthProvider>
          <Component {...pageProps} />
          <Tooltip
            id={'app-tooltip-instance'}
            className={'fitify-tooltip'}
            noArrow
          />
          <Tooltip
            id={'app-tooltip-with-arrow-instance'}
            className={'fitify-tooltip'}
          />
          <ToastContainer />
        </AuthProvider>
      </Provider>
    </SkeletonTheme>
  )
}
export default withErrorBoundary(FitifyCoachAdmin)
