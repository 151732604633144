import { Timestamp } from 'firebase/firestore'
import { Timestamp as AdminTimestamp } from 'firebase-admin/firestore'

export type FirestoreDocumentId = string
export type WithId<T extends object = object> = T & { id: FirestoreDocumentId }
export type Without<T, U> = { [P in Exclude<keyof T, keyof U>]?: never } & U
export type XOR<T, U> = Without<T, U> | Without<U, T>

export enum UnitSystem {
  Metric = 'metric',
  Imperial = 'imperial',
}

export type SerializableTimestamp = {
  _seconds: number
  _nanoseconds: number
}

export type FirestoreTimestamp =
  | Timestamp
  | AdminTimestamp
  | SerializableTimestamp
  | null

export type LatLng = {
  lat: number
  lng: number
}

export type Condition = {
  op: '===' | '!==' | '>' | '<' | '>=' | '<='
  path: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any
}
