import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { useErrorBoundary } from 'react-error-boundary'
import { useTranslation } from 'react-i18next'

import { getRoutePathname } from '@/router/routes'

import Button from '../Button/Button'
import Text from '../Text/Text'

import {
  StyledErrorButtons,
  StyledErrorDelimiter,
  StyledErrorFallback,
  StyledErrorFallbackWrapper,
  StyledErrorMessage,
} from './ErrorBoundary.Styled'

type ReduxRejectedActionError = { error: Error }

type ErrorFallbackProps = {
  error: Error | ReduxRejectedActionError
}

const getErrorMessage = (error: ErrorFallbackProps['error']) => {
  if ('error' in error) {
    return error.error.message
  }
  return error.message
}

const ErrorFallback: React.FC<ErrorFallbackProps> = ({ error }) => {
  const { t } = useTranslation()
  const { resetBoundary } = useErrorBoundary()

  const router = useRouter()

  const handleGoBack = () => {
    resetBoundary()

    if (router.query.userId && router.query.date) {
      router.push({
        pathname: getRoutePathname('activityCalendar'),
        query: {
          userId: router.query.userId,
          currentDate: router.query.date,
        },
      })
    } else {
      router.push({
        pathname: '/',
      })
    }
  }

  useEffect(() => {
    const handleRouteChange = () => {
      window.location.reload()
    }

    router.events.on('routeChangeStart', handleRouteChange)

    return () => {
      router.events.off('routeChangeStart', handleRouteChange)
    }
  }, [router])

  return (
    <StyledErrorFallback>
      <StyledErrorFallbackWrapper>
        <Text
          variant={'h1'}
          fontFamily="Graphik XXCond Web"
          fontWeight={500}
          color={'Black'}
          as="span"
        >
          {t('error_boundary_generic_title')}
        </Text>

        <StyledErrorDelimiter />
        <StyledErrorMessage>
          <Text
            variant={'md'}
            fontFamily="Graphik LCG Web"
            fontWeight={500}
            color={'Black'}
            as="span"
          >
            {getErrorMessage(error)}
          </Text>
        </StyledErrorMessage>

        <StyledErrorButtons>
          <Button variant={'black'} onClick={handleGoBack}>
            {t('button_error_go_back')}
          </Button>
          <Button variant={'black'} onClick={resetBoundary}>
            {t('button_error_try_again')}
          </Button>
        </StyledErrorButtons>
      </StyledErrorFallbackWrapper>
    </StyledErrorFallback>
  )
}

export default ErrorFallback
