import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit'
import { HumanCoaching } from 'fitify-types/src/types/human-coaching'

import { NonFitifyActivitiesService } from '@/api/services/NonFitifyActivitiesService'
import { AppDispatch, AppState } from '@/store/store'

type ActivitySamplesState = {
  heart_rate: {
    ids: string[]
    entities: Record<string, HumanCoaching.NonFitifyHeartRateSample[]>
    loading: boolean
    error: null | string
  }
  distance: {
    ids: string[]
    entities: Record<string, HumanCoaching.NonFitifyDistanceSample[]>
    loading: boolean
    error: null | string
  }
  elevation: {
    ids: string[]
    entities: Record<string, HumanCoaching.NonFitifyElevationSample[]>
    loading: boolean
    error: null | string
  }
  position: {
    ids: string[]
    entities: Record<string, HumanCoaching.NonFitifyPositionSample[]>
    loading: boolean
    error: null | string
  }
}

type FetchAllSamplesParams = {
  userId: string
  activityId: string
  type:
    | 'heart-rate-samples'
    | 'distance-samples'
    | 'elevation-samples'
    | 'position-samples'
}

const fetchAllSamples = <T>(
  type:
    | 'heart-rate-samples'
    | 'distance-samples'
    | 'elevation-samples'
    | 'position-samples'
) => {
  return createAsyncThunk<
    T[],
    FetchAllSamplesParams,
    {
      dispatch: AppDispatch
      state: AppState
    }
  >(`activitySamples/${type}`, async (data: FetchAllSamplesParams) => {
    return NonFitifyActivitiesService.getActivitySamples<T[]>(
      data.userId,
      data.type,
      data.activityId
    )
  })
}

export const fetchAllHeartRateSamples =
  fetchAllSamples<HumanCoaching.NonFitifyHeartRateSample>('heart-rate-samples')
export const fetchAllPositionSamples =
  fetchAllSamples<HumanCoaching.NonFitifyPositionSample>('position-samples')
export const fetchAllDistanceSamples =
  fetchAllSamples<HumanCoaching.NonFitifyDistanceSample>('distance-samples')
export const fetchAllElevationSamples =
  fetchAllSamples<HumanCoaching.NonFitifyElevationSample>('elevation-samples')

type SamplePayload =
  | HumanCoaching.NonFitifyHeartRateSample
  | HumanCoaching.NonFitifyPositionSample
  | HumanCoaching.NonFitifyDistanceSample
  | HumanCoaching.NonFitifyElevationSample

const processSamplesForStateKey = (
  state: ActivitySamplesState,
  key: keyof ActivitySamplesState,
  payload: SamplePayload[],
  activityId: string
) => {
  const uniqueIds = new Set(state[key].ids)
  uniqueIds.add(activityId)
  state[key].ids = Array.from(uniqueIds)
  state[key].entities[activityId] = []

  payload.forEach((item) => {
    if (key === 'heart_rate') {
      state[key].entities[activityId].push(
        item as HumanCoaching.NonFitifyHeartRateSample
      )
    } else if (key === 'position') {
      state[key].entities[activityId].push(
        item as HumanCoaching.NonFitifyPositionSample
      )
    } else if (key === 'distance') {
      state[key].entities[activityId].push(
        item as HumanCoaching.NonFitifyDistanceSample
      )
    } else if (key === 'elevation') {
      state[key].entities[activityId].push(
        item as HumanCoaching.NonFitifyElevationSample
      )
    }
  })
}

const initialState: ActivitySamplesState = {
  heart_rate: {
    entities: {},
    ids: [],
    loading: false,
    error: null,
  },
  distance: {
    entities: {},
    ids: [],
    loading: false,
    error: null,
  },
  elevation: {
    entities: {},
    ids: [],
    loading: false,
    error: null,
  },
  position: {
    entities: {},
    ids: [],
    loading: false,
    error: null,
  },
}

const activitySamplesSlice = createSlice({
  name: 'activitySamples',
  initialState,
  reducers: {
    resetState: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllHeartRateSamples.pending, (state) => {
        state.heart_rate.loading = true
      })
      .addCase(fetchAllHeartRateSamples.fulfilled, (state, action) => {
        state.heart_rate.loading = false
        processSamplesForStateKey(
          state,
          'heart_rate',
          action.payload,
          action.meta.arg.activityId
        )
      })
      .addCase(fetchAllHeartRateSamples.rejected, (state, action) => {
        state.heart_rate.loading = false
        state.heart_rate.error =
          action.error.message !== undefined ? action.error.message : null
        state.heart_rate.loading = false
      })
      .addCase(fetchAllPositionSamples.pending, (state) => {
        state.position.loading = true
      })
      .addCase(fetchAllPositionSamples.fulfilled, (state, action) => {
        state.position.loading = false
        processSamplesForStateKey(
          state,
          'position',
          action.payload,
          action.meta.arg.activityId
        )
      })
      .addCase(fetchAllPositionSamples.rejected, (state, action) => {
        state.position.loading = false
        state.position.error =
          action.error.message !== undefined ? action.error.message : null
        state.position.loading = false
      })
      .addCase(fetchAllDistanceSamples.pending, (state) => {
        state.distance.loading = true
      })
      .addCase(fetchAllDistanceSamples.fulfilled, (state, action) => {
        state.distance.loading = false
        processSamplesForStateKey(
          state,
          'distance',
          action.payload,
          action.meta.arg.activityId
        )
      })
      .addCase(fetchAllDistanceSamples.rejected, (state, action) => {
        state.distance.loading = false
        state.distance.error =
          action.error.message !== undefined ? action.error.message : null
        state.distance.loading = false
      })
      .addCase(fetchAllElevationSamples.pending, (state) => {
        state.elevation.loading = true
      })
      .addCase(fetchAllElevationSamples.fulfilled, (state, action) => {
        state.elevation.loading = false
        processSamplesForStateKey(
          state,
          'elevation',
          action.payload,
          action.meta.arg.activityId
        )
      })
      .addCase(fetchAllElevationSamples.rejected, (state, action) => {
        state.elevation.loading = false
        state.elevation.error =
          action.error.message !== undefined ? action.error.message : null
        state.elevation.loading = false
      })
  },
})

export const createActivityPositionSamplesSelector = (activityId: string) =>
  createSelector(
    [(state: AppState) => state.activitySamples.position.entities],
    (entities) => entities[activityId] || []
  )

export const createActivityHeartRateSamplesSelector = (activityId: string) =>
  createSelector(
    [(state: AppState) => state.activitySamples.heart_rate.entities],
    (entities) => entities[activityId] || []
  )

export const createActivityDistanceSamplesSelector = (activityId: string) =>
  createSelector(
    [(state: AppState) => state.activitySamples.distance.entities],
    (entities) => entities[activityId] || []
  )

export const createActivityElevationSamplesSelector = (activityId: string) =>
  createSelector(
    [(state: AppState) => state.activitySamples.elevation.entities],
    (entities) => entities[activityId] || []
  )

export const { resetState } = activitySamplesSlice.actions

export default activitySamplesSlice.reducer
