import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { Coach } from 'fitify-types/src/types/coach'
import { UnitSystem, WithId } from 'fitify-types/src/types/common'

import { UserService } from '@/api/services/UserService'
import { userInitialState } from '@/store/common/userInitialState'
import { Fitify } from '@/types/user'
import { mapDefaultValues } from '@/utils/user'

import { AppDispatch, AppState } from '../store'

interface LoggedUserState {
  user: Fitify.User
  coaches?: WithId<Coach>[]
  loading: boolean
  error: null | string
  isLoaded: boolean
}

const initialState: LoggedUserState = {
  isLoaded: false,
  user: userInitialState.user,
  loading: false,
  error: null,
}

export const fetchLoggedUserDetails = createAsyncThunk<
  Fitify.User,
  string,
  {
    dispatch: AppDispatch
    state: AppState
  }
>('user/fetchLoggedUserDetails', async (id: string) => {
  return UserService.getDetails(id)
})

export const fetchAllCoaches = createAsyncThunk(
  'user/fetchAllCoaches',
  async () => {
    return UserService.getAllCoaches()
  }
)

const loggedUserSlice = createSlice({
  name: 'loggedUser',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchLoggedUserDetails.pending, (state) => {
      state.loading = true
    })
    builder.addCase(fetchLoggedUserDetails.fulfilled, (state, action) => {
      state.user = mapDefaultValues(action.payload)
      state.loading = false
      state.isLoaded = true
    })
    builder.addCase(fetchLoggedUserDetails.rejected, (state, action) => {
      state.loading = false
      state.error =
        action.error.message !== undefined ? action.error.message : null
    })
    builder.addCase(fetchAllCoaches.pending, (state) => {
      state.loading = true
    })
    builder.addCase(fetchAllCoaches.fulfilled, (state, action) => {
      state.coaches = action.payload
      state.loading = false
    })
    builder.addCase(fetchAllCoaches.rejected, (state, action) => {
      state.loading = false
      state.error =
        action.error.message !== undefined ? action.error.message : null
    })
  },
})

export const userClientSelector = (state: AppState) => state.user
export const loggedUserSelector = (state: AppState) => state.loggedUser
export const loggedUserUnitSystemSelector = (state: AppState) =>
  state.loggedUser.user.details.profile?.units ?? UnitSystem.Imperial
export default loggedUserSlice.reducer
