import { StyledIcon } from '../Icon.Styled'
import { IconProps } from '../Icon.Types'

export const DumbbellDiagonalIcon = (props: IconProps) => (
  <StyledIcon
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <path
      d="M11.0435 0.713161C10.0926 1.66404 10.0926 3.20572 11.0435 4.1566L19.8434 12.9565C20.7943 13.9074 22.336 13.9074 23.2868 12.9565C24.2377 12.0056 24.2377 10.464 23.2868 9.51307L14.4869 0.713161C13.5361 -0.23772 11.9944 -0.23772 11.0435 0.713161Z"
      fill="currentColor"
    />
    <path
      d="M0.980983 19.346C0.304802 18.6698 0.304802 17.5735 0.980984 16.8973C1.15003 16.7283 1.42411 16.7283 1.59315 16.8973L7.10266 22.4068C7.27171 22.5759 7.2717 22.85 7.10266 23.019C6.42648 23.6952 5.33017 23.6952 4.65399 23.019L0.980983 19.346Z"
      fill="currentColor"
    />
    <path
      d="M16.8973 1.59315C16.7283 1.42411 16.7283 1.15003 16.8973 0.980984C17.5735 0.304803 18.6698 0.304802 19.346 0.980984L23.019 4.65399C23.6952 5.33017 23.6952 6.42648 23.019 7.10266C22.85 7.2717 22.5759 7.27171 22.4068 7.10266L16.8973 1.59315Z"
      fill="currentColor"
    />
    <path
      d="M0.71316 14.4869C-0.23772 13.5361 -0.23772 11.9944 0.71316 11.0435C1.66404 10.0926 3.20572 10.0926 4.1566 11.0435L12.9565 19.8434C13.9074 20.7943 13.9074 22.336 12.9565 23.2868C12.0056 24.2377 10.4639 24.2377 9.51307 23.2868L0.71316 14.4869Z"
      fill="currentColor"
    />
    <path
      d="M8.55656 13.1478C8.34525 13.3591 8.34525 13.7017 8.55656 13.913L10.087 15.4434C10.2983 15.6547 10.6409 15.6547 10.8522 15.4434L15.4434 10.8522C15.6547 10.6409 15.6547 10.2983 15.4434 10.087L13.913 8.55656C13.7017 8.34525 13.3591 8.34525 13.1478 8.55656L8.55656 13.1478Z"
      fill="currentColor"
    />
  </StyledIcon>
)
